import React from "react";
const Action = (props) => {
  return (
    <>
      <div>
        <button className="big-button"><a href="https://qywdp.com/"><strong className="big-button">企业网平台提供</strong></a>       
        </button>
        <button
          className="big-button"
          onClick={props.handlePickOption}
          disabled={!props.hasOptions}>
          随机输出一个选项          
        </button>
      </div>
    </>
  )
}
export default Action;