import React from "react";
import ReactModal from "react-modal";
ReactModal.setAppElement('body');
const Modal = (props) => {
    return (
        <ReactModal
            isOpen={!!props.selectedOption}
            closeTimeoutMS={200} //过度效果
            className="modal" //使用自己的样式
            onRequestClose={props.handleClearSelectedOption}>
            <h3 className="modal__title">选中的选项</h3>
            <p className="modal__body">{props.selectedOption}</p>
            <button className="button" onClick={props.handleClearSelectedOption}>好的</button>
        </ReactModal>
    )
}

export default Modal;