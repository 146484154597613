import React from "react";
import Option from "./Option";
const Options = (props) => {
  return (
    <div>
      <div className="widget-header">       
        <h3 className="widget-header--title"><a href="https://qywdp.com/wholesales"><strong className="button button--link">批发中心</strong></a></h3>
        <h3 className="widget-header--title"><a href="https://qywdp.com/applicationBownloads"><strong className="app-option-error">应用下载</strong></a></h3>
        <h3 className="widget-header--title"><a href="https://qywdp.com/myBookkeepings"><strong className="button button--link">记账本</strong></a></h3>       
      </div>
      <div className="widget-header">
        <h3 className="widget-header--title">您的选项</h3>
        <h3 className="widget-header--title"><a href="https://video.qywdp.com"><strong className="app-option-error">视频会议</strong></a></h3>
        <button
          className="button button--link"
          onClick={props.handleRemoveAll}>
          删除所有
        </button>
      </div>


      {props.options.length === 0 && <p className="widget--message">请添加一个选项</p>}
      {
        props.options.map((option, index) => (
          <Option
          count={index +1}
            option={option}
            key={`option_${index}`}
            handleRemoveOption={props.handleRemoveOption}
          />
        ))
      }
    </div>
  )
}
export default Options;