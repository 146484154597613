import React from "react";
const Header = (props) => {
  return (
    <div className="header">
      <div className="container">
        <h1 className="header--title">{props.title}</h1>
        {props.subTitle && <h2 className="header--subTitle">{props.subTitle}</h2>}
      </div>
    </div>
  )
}
Header.defaultProps = {
  title: "把您的命运交给电脑"
}
export default Header;